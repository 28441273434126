@import "global.css";

body {
  font-family: 'Poppins', sans-serif;
}
p {
  line-height: 24px;
}
p, li {
  color: var(--secondary-clr);
}
.swiper-wrapper h2 {
  font-size: var(--font-58) !important;
}
.greenLetter {
  color: var(--primary-clr);
  font-weight: 500;
}
.mediumFont {
  font-weight: 500;
}
.btn-primary {
  background-color: var(--primary-clr) !important;
  border-color: var(--primary-clr) !important;
  font-size: 18px;
  font-weight: 500;
}
.rightbar {
  width: calc(100% - 320px);
}
.grey {
  background-color: var(--grey);
}
.form-check-input:checked {
  background-color: var(--primary-clr) !important;
  border-color: var(--primary-clr) !important;
}
.bigradius {
  border-radius: 12px;
}
.dashboardBoxIcon {
  background-color: var(--primary-clr);
}
.dashboardBox h5 {
  color: var(--primary-clr);
}
.pendingDot {
  width: 10px;
  height: 10px;
  background-color: rgba(251, 199, 94, 1);
}
.dangerDot {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 113, 139, 1);
}
.successDot {
  width: 10px;
  height: 10px;
  background-color: rgba(62, 223, 165, 1);
}
.dropdown-toggle:focus {
  box-shadow: none;
}
.progress-bar {
  background-color: var(--primary-clr) !important;
}
.progress-bar:after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: var(--white);
  border: 4px solid var(--primary-clr);
  border-radius: 100%;
  right: -5px;
}
.topList-slider p {
  font-size: 13px;
}
/* .table-search button {
  top: 0 !important;
} */
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #ffffff !important;
}

.Mui-checked+.MuiSwitch-track {
  background-color: var(--primary-clr) !important;
}
.modal-backdrop {
  opacity: .8 !important;
}
.modal-body form input {
  min-height: 52px;
}
.modal-title.h4 {
  font-size: 18px;
  color: rgba(69, 70, 78, 1) !important;
}
.recentInvoice p {
  font-size: 14px;
}
/* Dropdown setting css */

.userDropdownInner {
  top: 60px;
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
  width: 350px;
  right: 20px;
  z-index: 999;
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 99;
}
.userDropdown-option:hover {
  background-color: var(--primary-clr);
  transition: .2s;
}
.userDropdown-option:hover span {
  color: #ffffff;
  transition: .1s;
}
.userDropdown-option:hover img {
  filter: brightness(2);
}
/* sidebar collapse css */

.collapseSidebar .leftbar {
  width: 70px;
  padding: 23px 10px !important;
}
.collapseSidebar .leftbar ul.sidemenu li span {
  display: none;
}
.collapseSidebar .leftbar ul.sidemenu li {
  padding: 10px 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collapseSidebar .leftbar ul.sidemenu li img {
  margin: 0 !important;
}
.collapseSidebar .rightbar {
  width: calc(100% - 70px);
}
.collapseSidebar .sidebarLogo {
  display: none;
}
.collapseSidebar .sidebarLogo {
  display: none;
}
.collapseSidebar .arrowBox {
  right: -34px;
}
.sidebarLogoIcon {
  display: none;
}
.collapseSidebar .sidebarLogoIcon {
  display: block;
}

/* sidebar css */
.contactSupport {
  background-color: #f1f1f1 !important;
}
.leftbar {
  width: 320px;
}
ul.sidemenu li.active {
  background-color: var(--primary-clr);
}
.bigRadius {
  border-radius: var(--font-12) !important;
}
ul.sidemenu li.active span {
  color: #ffffff;
}
ul.headerIcons li {
  padding: 10px 13px;
}
.arrowBox {
    background-color: var(--primary-clr);
    right: -22px;
    padding: 4px 6px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.eye {
  right: 15px;
  top: 14px;
  cursor: pointer;
}
.authPage p {
  color: var(--text-secondary);
}
.authPage p.or:before {
  content: '';
  position: absolute;
  background-color: #C7CAE1;
  height: 1px;
  width: 45%;
  left: 0;
  top: 14px;
}

.authPage p.or:after {
  content: '';
  position: absolute;
  background-color: #C7CAD0;
  height: 1px;
  width: 45%;
  right: 0;
  top: 14px;
}
.cursorPointer {
  cursor: pointer;
}
.nav-tabs button.nav-link {
  background-color: #f1f1f1;
}
.swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {  
  background-color: #ffffff;    
  width: 60px;
}
.swiper-pagination span.swiper-pagination-bullet {
  width: 40px;
  height: 5px;
  border-radius: 5px;
}
.swiper, .swiper-wrapper {
  min-height: 300px;
  overflow-y: visible;
}
.swiper-pagination {
  left: 0 !important;
  text-align: left;
  bottom: 0 !important;
}

.backOption {
  top: 8px;
  cursor: pointer;
}
.termsContainer {
  background-color: rgba(241, 241, 241, 1);
}
ul.sidemenu {
  height: 60%;
  width: 100%;
}
.userSidebar ul.sidemenu {
  height: auto !important;
  width: 100%;
}
ul.bottomUl {
  height: 20%;
}
.userSidebar ul.bottomUl {
  height: auto !important;
}

.sidebarMain.userSidebar {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 600px;
}

ul.sidemenu li.active a span {
  color: #ffffff !important;
}


/* Invoice Timeline css */

.timeline-list h5 {
  font-size: 14px;
  font-weight: 600;
}
.timeline-heading h5 {
  font-size: 14px;
  font-weight: 600;
}
.timeline-list p {
  font-size: 13px;
  font-weight: 600;
}
/* .clrprimary {
  background-color: var(--primaryColor);
  border-left: 5px solid var(--primaryColorText);
}
.clrprimary h5, .clrprimary p {
  color:  var(--primaryColorText);
} */
.clrreceived {
  background-color: rgba(2, 160, 252, 0.11);
  border-left: 5px solid rgba(2, 160, 252, 1);
}
.clrreceived h5, .clrreceived p {
  color: rgba(2, 160, 252, 1);
}
.clraiVerification {
  background-color: rgba(52, 181, 58, 0.11);
  border-left: 5px solid rgba(52, 181, 58, 1);
}
.clraiVerification h5, .clraiVerification p {
  color:  rgba(52, 181, 58, 1);
}
.clraiVerified {
  background-color: rgba(255, 178, 0, 0.11);
  border-left: 5px solid rgba(255, 178, 0, 1);
}
.clraiVerified h5, .clraiVerified p {
  color:  rgba(255, 178, 0, 1);
}
.clraiRejected {
  background-color: rgba(255, 44, 183, 0.11);
  border-left: 5px solid rgba(255, 44, 183, 1);
}
.clraiRejected h5, .clraiRejected p {
  color:  rgba(255, 44, 183, 1);
}
.clrsendApprover {
  background-color: rgba(21, 86, 168, 0.11);
  border-left: 5px solid rgba(21, 86, 168, 1);
}
.clrsendApprover h5, .clrsendApprover p {
  color:  rgba(21, 86, 168, 1);
}
.clrapproved {
  background-color: rgba(0, 119, 255, 0.11);
  border-left: 5px solid rgba(0, 119, 255, 1);
}
.clrapproved h5, .clrapproved p {
  color:  rgba(0, 119, 255, 1);
}
.clrrejected {
  background-color: rgba(254, 80, 81, 0.11);
  border-left: 5px solid rgba(254, 80, 81, 1);
}
.clrrejected h5, .clrrejected p {
  color:  rgba(254, 80, 81, 1);
}
.clrscheduled {
  background-color: rgba(115, 87, 39, 0.11);
  border-left: 5px solid rgba(115, 87, 39, 1);
}
.clrscheduled h5, .clrscheduled p {
  color:  rgba(115, 87, 39, 1);
}
.clrpaid {
  background-color: rgba(0, 157, 124, 0.11);
  border-left: 5px solid rgba(0, 157, 124, 1);
}
.clrpaid h5, .clrpaid p {
  color:  rgba(0, 157, 124, 1);
}


.timeline-heading h5, .timeline-list {
    width: 14.28%;
}
.collapseSidebar .leftbar ul.bottomUl li span {
  display: none;
}
.collapseSidebar .leftbar ul.bottomUl li img {
  margin-right: 0 !important;
}

.collapseSidebar .leftbar ul.bottomUl li {
  padding: 10px 15px !important;
}


/* account setting modal css */

.profileEdit {
  background-color: var(--primary-clr);
  width: 20px;
  height: 20px;
  bottom: 0;
  right: -5px;
}

span.profileEdit img {
  margin: 0 0 9px 2px;
}
.sendToApproverBg {
  background-color: rgba(21, 86, 168, 0.11) !important;
  color: rgba(21, 86, 168, 1) !important;
}
.receiveBg {
  background-color: rgba(2, 160, 252, .11) !important;
  color: rgba(2, 160, 252, .11) !important;
}
/* Invoice details page css */

.reject, .reject:hover {
  background-color: var(--danger) !important;
}
.approve, .approve:hover {
  background-color: var(--primary-clr) !important;
}
.purpleBg {
  background-color: var(--purpleColor);
  border: 1px solid var(--purpleColorText);
  color: var(--purpleColorText);
}
.dangerBg {
  background-color: var(--dangerColorText) !important;
  border: 1px solid var(--dangerColor) !important;
  color:  var(--dangerColor) !important;
}
/* .rejected {
  background-color: rgba(254, 80, 81, 0.11) !important;
} */
/* .rejectedText {
  color: rgba(254, 80, 81, 1) !important;
} */
.scheduledCalender {
  background-color: var(--warningColor);
  color: var(--warningColorText);
}
.warningBg {
  background-color: var(--warningColor);
  border: 1px solid var(--warningColorText);
  color: var(--warningColorText);
}
.verifiedBg {
  background-color: var(--verifiedColor);
  border: 1px solid var(--verifiedColorText);
  color: var(--verifiedColorText);
}
.successBg {
  background-color: var(--successColor);
  border: 1px solid var(--successColorText);
  color: var(--successColorText);
}
.generalInfo {
  background-color: rgba(231, 231, 231, 1);
}
.generalInfoDetail p, .generalTimeline {
  border-bottom: 1px solid rgba(0,0,0,0.2);
  font-size: 16px;
  font-weight: 500;
}
.generalTimeline .approve {
  width: 32px;
  height: 32px;
  border: 2px solid rgba(10, 192, 94, 0.2);
}
.generalTimeline .reject {
  width: 32px;
  height: 32px;
  border: 2px solid rgba(225, 66, 85, 1);
}
.generalTimeline .default {
  width: 32px;
  height: 32px;
  border: 2px solid rgba(10, 192, 94, 0.2);
  z-index: 0;
}
.generalTimeline .currentDot.default {
  border: 2px solid #0ac15e;
}
.generalTimeline {
  border: none;
}
.generalTimeline .approve:after, .generalTimeline .reject:after, .generalTimeline .default:after {
  content: '';
  position: absolute;
  background-color: rgba(10, 192, 94, 0.11);
  height: 40px;
  width: 3px;
  bottom: -41px;
}
.generalTimeline .default:before {
  content: '';
  position: absolute;
  background-color: rgba(10, 192, 94, 0.2);
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.generalTimeline:last-child .default:after {
  display: none;
} 
.timelineStatus {
  font-size: 10px;
}

/* Notification css */

.notificationDropdown {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  right: 0;
  width: 500px;
  z-index: 9;
  top: 60px;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: var(--primary-clr);
  color: #ffffff !important;
}

ul.contactList li.active {
  background-color: var(--primary-clr);
  color: #ffffff;
}
.table-search button {
  top: 7px;
}
.addBtn {
  background-color: var(--primary-clr);
  padding: 10px 20px;
}
.addContract span {
  font-size: 12px;
}

/* Upload invoice css */

.ant-upload.ant-upload-drag {
    height: 400px;
}
.ant-upload.ant-upload-drag span.ant-upload.ant-upload-btn {
    width: 100%;
}
span.browse {
    color: var(--primary-clr);
}
span.browse {
  color: var(--primary-clr);
}
.ant-upload-list.ant-upload-list-text .ant-upload-list-item {
  padding: 10px;
  margin: 0;
  background-color: #ffffff;
  min-height: 45px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin-top: 15px;
}
.pdfUploadBtn, .pdfUploadBtn:hover {
  background-color: var(--primary-clr) !important;
}
.ant-picker-calendar-date-content {
  min-height: 115px !important;
  height: 115px;
}
.events .ant-badge-status {
  border-left: 5px solid var(--primary-clr);  
}
.ant-picker-calendar-date-value {
  color: #333333 !important;
  padding: 4px 9px;
  display: inline-block;
  border-radius: 100%;
  height: 30px;
}
.ant-picker-cell:hover .ant-picker-calendar-date-value {
  background-color: #cae1f3;
  color: #333333 !important;
  padding: 4px 9px;
  display: inline-block;
  border-radius: 100%;
  height: 30px;
}
.ant-picker-cell-today .ant-picker-calendar-date-value {
  background-color: var(--primary-clr) !important;
  color: #ffffff !important;
  padding: 4px 9px;
  display: inline-block;
  border-radius: 100%;
  height: 30px;
}
.ant-picker-cell-today .ant-picker-calendar-date-value {
  background-color: var(--primary-clr) !important;
  color: #ffffff !important;
  padding: 4px 9px;
  display: inline-block;
  border-radius: 100%;
  height: 30px;
}
.ant-picker-cell-inner.ant-picker-calendar-date.ant-picker-calendar-date-today {
  border-top: 2px solid rgba(5, 5, 5, 0.06) !important;
}
.outlineBtn {
  border: 2px solid var(--primary-clr) !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-picker-calendar .ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 20%;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-picker-calendar .ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 20%;
}

span.action {
  cursor: pointer;
}
.rightbar {
  min-height: 100vh;
}
.rightInner {
    min-height: 83vh;
}
.nav.nav-pills .nav-link.active {
  background-color: var(--primary-clr);
  color: #ffffff !important;
}
.dateSelect {
  width: 170px !important;
}
.addContract .ant-upload.ant-upload-drag {
  height: 200px;
}
.addContract form input {
  min-height: auto !important;
}
label {
  font-weight: 500;
}
.delIcon, ul.contactList li, ul.headerIcons li, .action-btn, .sidebarLogo {
  cursor: pointer !important;
}
/* .userDropdown-option {
  cursor: auto;
} */
.profileDropDowns {
  cursor: auto !important;
}
/* .userDropdownInner > div {
  cursor: pointer !important;
} */
.userDropdown-option, .run-rate select, .dashboardBox select {
  cursor: pointer !important;
}
.MuiSwitch-track, span.MuiSwitch-thumb {
  background-color: var(--primary-clr) !important;
}
.vendorDetails .Mui-checked .MuiSwitch-thumb {
  background-color: var(--primary-clr) !important;
}
.vendorDetails .MuiSwitch-thumb {
  background-color: var(--text-secondary) !important;
}
.vendorDetails .MuiSwitch-track{
  background-color: var(--text-secondary) !important;
}

.statusToggle .Mui-checked .MuiSwitch-thumb {
  background-color: var(--primary-clr) !important;
}
.statusToggle .MuiSwitch-thumb {
  background-color: var(--text-secondary) !important;
}
.statusToggle .MuiSwitch-track{
  background-color: var(--text-secondary) !important;
}



/* .statusToggle .MuiSwitch-thumb {
  background-color: var(--text-secondary) !important;
}
.statusToggle .MuiSwitch-track{
  background-color: var(--text-secondary) !important;
} */


form.search button {
  top: 10px !important;
}
.accessPermission .form-check-input {
  width: 1.5em;
  height: 1.5em;
}
th.ant-table-cell {
  padding: 5px;
}
.headerIcons li.active {
  background-color: var(--primary-clr);
}
tbody.ant-table-tbody a:hover, tbody.ant-table-tbody a {
  text-decoration: underline;
}
.invoiceNumber:hover {
  text-decoration: underline !important;
}
.notiOver {
    z-index: 8;
}
li.ant-pagination-item {
  margin-right: 2px !important;
}
li.ant-pagination-prev {
  margin-right: 0 !important;
}
input#FileInput {
  font-size: 0;
  width: 20px;
  min-height: 20px;
  position: absolute;
  top: 0;
  opacity: 0;
  right: 0;
}
.profileEdit img {
  margin: -11px 0 0 2px;
}
.addBtn:focus {
  box-shadow: none;
  outline: none;
}
.table-search .blankk {
  background-color: #f8f9fa;
  width: 40px;
  height: 50px;
  right: 3px;
  top: 4px;
  z-index: 0;
}
input[type="search"]::-webkit-search-cancel-button {
  z-index: 9
}
.greenFont label {
  color: var(--primary-clr);
}
.loaderImg {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

}
.loaderImg img {
  width: 80px;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.9);
}
li.ant-pagination-item-active {
  background-color: var(--primary-clr) !important;
  color: #ffffff;
  border: none !important;
}
.smallClaendar .ant-picker-calendar-date-today {
  background: none !important;
  border: none !important;
}
.ant-picker-cell-inner.ant-picker-calendar-date.ant-picker-calendar-date-today {
  border-top: 2px solid #ffffff !important;
}
.smallClaendar .ant-picker-calendar-date-today:before {
  border: none !important;
  margin: 0 !important;
  background: transparent !important;
}
.modalVendor .modal-dialog {
  max-width: 800px !important;
}
tbody.ant-table-tbody a {
  text-decoration: none;
}
tbody.ant-table-tbody a:hover {
   text-decoration: underline;
}
.addContract .invoicePreview {
  height: 565px;
}
input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}
.roleDown i.fa {
  top: 20px;
  right: 10px;
}
.ant-radio-group .ant-radio-button-wrapper-checked {
  border-color: var(--primary-clr) !important;
}
.ant-radio-group .ant-radio-button-wrapper-checked span.ant-radio-button.ant-radio-button-checked {
  background-color: var(--primary-clr);
}
.ant-radio-group .ant-radio-button-wrapper-checked {
  color: #ffffff !important;
}
.Mui-checked+.MuiSwitch-track {
  background-color: var(--primary-clr) !important;
}
.userDropdown, .userDropdownInner > div, .sidebarLogoIcon {
  cursor: pointer;
}
.ant-picker-cell-inner.ant-picker-calendar-date {
  background: none !important;
}
.input-group .error {
  display: none;
}
.input-group input {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.waitingApproval {
  background-color: var(--primaryColor) !important;
}
.waitingApprovalText {
  color: var(--primaryColorText) !important;
} 
.approved {
  background-color: rgba(0, 119, 255, 0.11) !important;
}
.approvedText {
  color: rgba(0, 119, 255, 1) !important;
}
.rejected {
  background-color: rgba(254, 80, 81, 0.11) !important;
}
.rejectedText {
  color: rgba(254, 80, 81, 1) !important;
}
.pending {
  background-color: var(--warningColor) !important;
}
.pendingText {
  color: var(--warningColorText) !important;
}
.scheduled {
background-color: rgba(115, 87, 39, 0.11) !important;
}
.scheduledText {
  color: rgba(115, 87, 39, 1) !important;
}
.sendApprover {
  background-color: rgba(21, 86, 168, 0.11) !important;
}
.sendApproverText {
  color: rgba(21, 86, 168, 1) !important;
}
.aiRejected {
  background-color: rgba(255, 44, 183, 0.11) !important;
}
.aiRejectedText {
    color: rgba(255, 44, 183, 1) !important;
}
.paid {
  background-color: rgba(0, 157, 124, 0.11) !important;
}
.paidText {
    color: rgba(0, 157, 124, 1) !important;
}
.aiVerified {
  background-color: rgba(255, 178, 0, 0.11) !important;
}
.aiVerifiedText {
    color: rgba(255, 178, 0, 1) !important;
}
.aiVerification {
  background-color: rgba(52, 181, 58, 0.11) !important;
}
.aiVerificationText {
    color: rgba(52, 181, 58, 1) !important;
}
.received {
  background-color: rgba(2, 160, 252, 0.11) !important;
}
.receivedText {
    color: rgba(2, 160, 252, 1) !important;
}
.invoiceAnalytics canvas {
  height: 400px !important;
  width: 100% !important;
}
.contactListDashboard {
  background-color: rgba(241, 241, 241, 1);
}
.timelineDevision {
  width: 150px !important;
}
.statusAdded img {
  z-index: 6;
  width: 32px;
  height: 32px;
}
.statusAdded .default {
  border: none;
}
.timeline-heading, .timelineData {
  min-width: 1800px;
}
.timelineContainer {
  overflow-x: scroll;
}
.timelineDevision, .timeline-heading h5 {
  width: 200px !important;
  min-width: 200px !important;
}
span.notiCount {
  padding: 2px 5px;
  font-size: 10px;
  top: 0;
}
.alertDialog .MuiPaper-root.MuiPaper-elevation {
  width: 30%;
}
.alertDialog .MuiPaper-root.MuiPaper-elevation textarea {
  background-color: #f5f5f5 !important;
}
.run-rate{
  width: 120px;
}

ul.contactList.contactListDashboard li {
  font-size: 12px;
  padding: 5px !important;
}
.clearBtn {
  top: 9px;
  right: 9px;
  background-color: #dbdcdd;
  padding: 3px 5px;
  line-height: 0;
  cursor: pointer;
  z-index: 0;
}
/* .table-search:focus .clearBtn {
  display: block !important;
} */
.fc-theme-standard a {
  color: #45464E;
  text-decoration: none;
}
.fc-theme-standard a {
  color: #45464E;
  text-decoration: none;
}
.fc-day-today .fc-daygrid-day-top a {
  background-color: #0ac05e;
  color: #ffffff;
  border-radius: 100%;
  font-size: 12px;
  padding: 5px 6px !important;
  line-height: 15px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fc-day-past .fc-daygrid-day-top a, .fc-day-future .fc-daygrid-day-top a {
  background-color: #ededed;
  color: #45464E;
  border-radius: 100%;
  font-size: 12px;
  padding: 5px 6px !important;
  line-height: 15px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calModal .react-calendar {
  width: 100%;
}
.timelineStatus {
  cursor: pointer;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--now, .react-calendar__tile--active:enabled:focus {
  background: #0ac05e !important;
}
.currentDot:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: var(--primary-clr) !important;
}
input::-webkit-search-cancel-button {
  display: none;
}
.srcCross button {
  top: 10px !important;
}
.srcCross .clearBtn {
  top: 17px !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  background-color: #07a952;
  border-color: #07a952;
}
.fc .fc-button-primary:disabled, .fc .fc-button-primary:hover {
  background-color: #0ac05e;
  border-color: #0ac05e;
}
.fc .fc-button-primary {
  background-color: #0ac05e;
  border-color: #0ac05e;
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:focus {
  box-shadow: none;
}
.respoBar {
  display: none !important;
}
.profileDropdown, .headerIcons {
  display: block !important;
}


/*== start of code for tooltips ==*/
.tool {
  position: relative;
}


/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -100;
}


.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100; 
}


/*== pointer tip ==*/
.tool::before {
  border-style: solid;
  border-width: 1em 0.75em 0 0.75em;
  border-color: var(--primary-clr) transparent transparent transparent;
  bottom: 25%;
  content: "";
  margin-left: -0.5em;
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
  transform: scale(.6) translateY(-90%);
  left: 157%;
} 


.tool:hover::before,
.tool:focus::before {
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
  transform: rotate(90deg);
}


/*== speech bubble ==*/
.tool::after {
  background: var(--primary-clr);
  border-radius: .25em;
  bottom: 0;
  color: #EDEFF0;
  content: attr(data-tip);
  padding: 10px;
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
  transform:  scale(.6) translateY(50%);  
  width: 17.5em;
  left: 200%;
  font-size: 12px;
  font-weight: bold;
}
.tool.uploaderTooltip::after {
  right: 200% !important;
}
.tool.uploaderTooltip::before {
  right: 157% !important;
}
.tool.uploaderTooltip::before, .tool.uploaderTooltip::after {
  right: 50% !important;
}
.tool:hover::after,
.tool:focus::after  {
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
}

.ant-table-wrapper .ant-table-column-title {
  z-index: 0 !important;
}
.timelineDevision a:hover {
  text-decoration: underline !important;
}
.searchBtn {
  padding-right: 42px !important;
}
.ant-upload-list-item-progress{
  padding-inline-start: 0px !important;
  left: 0 !important;
  bottom: -17px !important;;
}
.pdfViewer nav button {
  background-color: var(--primary-clr);
  border: none;
  color: #ffffff !important;
  padding: 10px;
  border-radius: 6px;
  margin-right: 10px;
  width: 60px;
  font-weight: 600;
}
.react-pdf__Document {
  /* height: 600px; */
  border: 2px solid #333333;
}
.pdfNav {
  top: 0;
  z-index: 999;
}
.arrowIcon i.fa {
  right: 10px;
  top: 13px;
}
.uploadIconBtn {
  background: var(--primary-clr);
}
.selectMonth input::-ms-input-placeholder { /* Edge 12-18 */
  color: #212529 !important;
}

.selectMonth input::placeholder {
  color: #212529 !important;
}
.clearBtn.setLimit {
  top: 17px !important;
}
.table-search button.searchIcon {
  top: 10px;
}
.table-search button.search-icon{
  top: 1px;
}
.pdfNav {
  z-index: 0;
}
.userDropdownInner p, .userDropdownInner small {
  cursor: text;
}
.closeHamberger{
  width: 18px;
  height: 18px;
}
.twoFa input {
  width: 50px !important;
  height: 50px;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--primary-clr) !important;
}
/* .disable2fa {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
} */
.modal.show, .modal.modal-dialog {
  z-index: 9999;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-picker .ant-picker-input >input {
  font-size: 16px !important;
}
.withHover {
  display: none;
}
.userDropdown-option:hover .withHover {
  display: inline-block;
  transition: .2s;
}
.userDropdown-option:hover .withoutHover {
  display: none;
  transition: .2s;
}
.scrollbar
{
	width:100%;
}

#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	background-color: #ffffff;
}

#style-4::-webkit-scrollbar
{
	width: 0;
	background-color: #ffffff;
}


select:focus, select:focus-visible {
  outline: none;
  border: none;
}
img.downArrow {
  right: 0;
  top: 9px;
}
button.showReport img.downArrow {
  transform: rotate(180deg);
  transition: .3s;
}
button.hideReport img.downArrow {
  transform: rotate(0deg);
  transition: .3s;
}
a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-daygrid-event.fc-daygrid-dot-event, a.fc-event.fc-event-start.fc-event-end.fc-event-future.fc-daygrid-event.fc-daygrid-dot-event {
  font-size: 12px;
}
.fc-daygrid-event-harness {
  cursor: pointer;
}
.moreBorder {
  border: 1px solid #45464e;
  border-radius: 10px;
}
.invoiceTableOnly table {
  width: 1199px;
}

.invoiceTableOnly {
  overflow-x: scroll;
}
.listView {
  background-color: rgba(241, 241, 241, 1);
}
.amountBg {
  background-color: rgba(10, 192, 94, 0.16);
  border: 1px solid rgba(10, 192, 94, 1);
  width: 50px;
  height: 30px;
}
.amountBg span {
  color: rgba(10, 192, 94, 1);
}
.listView > div {
  min-height: 60px;
}
.overFlow {
  overflow-y: scroll;
  max-height: 600px;
}
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 2px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: var(--primary-clr);
}
.listViewData:last-child {
  margin-bottom: 0 !important;
}
.form-check-input {
  border: 1px solid var(--primary-clr) !important;
}
.verifying{
  color: rgb(255 0 0 / 50%);
}
.manualWorkBox {
  width: 120px;
  height: 120px;
  background-color: var(--primary-clr);
}
.manualWorkContent p.head {
  background-color: var(--primary-clr);
}
.manualWorkBox {
  border: 2px solid #ffffff;
  margin-bottom: -15px;
}
.manualWorkContent {
  border-radius: 40px;
  background-color: var(--bggrey);
  min-height: 545px;
  box-shadow: 0 0 10px rgb(56 56 56 / 15%);
}
.manualWorkContent ul li {
  color: var(--greyDark);
}
li.primary {
  color: var(--primary-clr) !important;
}
.manualWork .manualWorkBoxArrow:after {
    content: '';
    position: absolute;
    background-image: url("../assets/Landing/line.svg");
    width: 100%;
    height: 25px;
    left: 110%;
    top: 40%;
    background-repeat: no-repeat;
    background-size: contain;
}
.manualWorkBoxArrow.arrowNone:after {
  display: none;
}
.manualWorkOne {
  width: 20%;
}
.manualWorkOne p {
  min-height: 75px;
}
.invoicePreview button {
  background-color: #f1f1f1;
  border: 1px solid #dddddd;
  width: 20px;
  height: 20px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 5px 0;
  font-size: 12px;
}
.invoicePreview button.active {
  background-color: #07a952;
  color: #ffffff;
  margin: 0 5px 5px 0;
} 